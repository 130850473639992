import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHomeAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  Box, Grid, Typography, Breadcrumbs, Button,
} from '@mui/material';

import { Link } from 'react-router-dom';

import { HOME, REPORTS } from 'Shared/Constant/Routes';
import { IReportsPage } from '../Interface';
import FiltersPage from './Filters/index';
import ResultPage from './Result/index';
import ModalImportFile from 'Shared/Components/ModalImportFile/index';
import { IModalImportFilePage } from 'Shared/Components/ModalImportFile/Interface';
import React from 'react';
import momenttz from 'moment-timezone';

import { ILayout } from 'Module/Layout/Interface';
import RendererExcelUtil from 'Shared/Utils/RendererExcel/index';
import { IRendererFileExcelStructure } from 'Shared/Interfaces/RendererFileExcel';

function ReportsPage(props: IReportsPage) {
  const [dateUpdateInfoModal, setDateUpdateInfoModal] = React.useState<string>(momenttz().tz('America/Sao_Paulo').format());
  const toggleDateUpdateInfoModal = () => {
    setDateUpdateInfoModal(momenttz().tz('America/Sao_Paulo').format());
  }

  const [structFile, setStructFile] = React.useState<IRendererFileExcelStructure>({
    listCols: [],
    columns: [],
    rows: []
  });
  const toggleStructFile = (struct: IRendererFileExcelStructure) => {
    setStructFile(struct)
  }

  const [fileToImport, setFileToImport] = React.useState<File | null>(null);
  const toggleFileToImportchange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    setFileToImport(selectedFile || null);

    if (selectedFile) {
      await RendererExcelUtil.Execute(selectedFile).then((response: IRendererFileExcelStructure) => {
        toggleStructFile(response);
        toggleDateUpdateInfoModal();
      })
        .catch((error) => {
          console.log(error);
          toggleDateUpdateInfoModal();
        })
    }
  }

  const handleTargets = () => {
    if (props.toggleUpdateTargets) {
      props.toggleUpdateTargets();
    }
  }

  const handleCadProdAuto = () => {
    if (props.toggleCreateProductAuto) {
      props.toggleCreateProductAuto();
    }
  }

  const handleProductsQtdeCx = () => {
    if (props.toggleProductsQtdeCx) {
      props.toggleProductsQtdeCx();
    }
  }

  const handleOpenModal = () => {
    const _structEmpty: IRendererFileExcelStructure = {
      listCols: [],
      columns: [],
      rows: []
    };
    toggleStructFile(_structEmpty);
    setFileToImport(null);

    if (props.toggleShowModalImportFile) {
      props.toggleShowModalImportFile(true)
    }
  }

  const handleImportFile = async (data: any) => {
    let filtro = true;
    let messageError = '';

    if (!fileToImport) {
      filtro = false;
      messageError += 'Anexo não informado! ';
    }

    if (filtro) {
      if (props.toggleImportFile) {
        props.toggleImportFile(structFile)
      }
      setFileToImport(null);
    }
    else {
      let layoutAlert: ILayout = {
        loading: false,
        typeMessage: 'error',
        titleMessage: 'Erro importar',
        message: messageError,
      }
      props.toggleSetLayout(layoutAlert);
    }
  }

  const handleimportfile: IModalImportFilePage = {
    ...props,
    origin: 'Report',
    title: 'Importar arquivo de Faturamento',
    fileToImport,
    dateUpdateInfoModal,
    structFile,
    toggleFileToImportchange,
    handleImportFile,
  }

  return (
    <Box id="Reports" key="Reports" className="ChildrenRoot">
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Typography component="div" align="left">
            <Box id="BreadcrumbsBox" className="Breadcrumbs">
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to={HOME}>
                  <FontAwesomeIcon icon={faHomeAlt} />
                </Link>
                <Link color="inherit" to={REPORTS}>
                  Relatório
                </Link>
              </Breadcrumbs>
            </Box>
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          {props.isTypeGroupEdit &&
            <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
              <Button
                variant="contained"
                color="success"
                className="ButtonPrimary"
                type="button"
                onClick={handleTargets}
              >
                <span>Atualizar Metas</span>
              </Button>
            </Typography>
          }
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          {props.isTypeGroupEdit &&
            <Typography component="div" align="left" sx={{ padding: '0px 5px 5px 0px' }}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Button
                    variant="contained"
                    color="success"
                    className="ButtonPrimary"
                    type="button"
                    onClick={handleProductsQtdeCx}
                  >
                    <span>Atualizar Produto Qtde. Cx. Fat.</span>
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                  <Button
                    variant="contained"
                    color="success"
                    className="ButtonPrimary"
                    type="button"
                    onClick={handleCadProdAuto}
                  >
                    <span>Cad. Prod. Automático</span>
                  </Button>
                </Grid>
              </Grid>
            </Typography>
          }
        </Grid>
        <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
          {props.isTypeGroupEdit &&
            <Typography component="div" align="right" sx={{ padding: '0px 5px 5px 0px' }}>
              <Button
                variant="contained"
                color="success"
                className="ButtonDanger"
                type="button"
                onClick={handleOpenModal}
              >
                <span>Importar Arquivo Excel</span>
              </Button>
            </Typography>
          }
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <FiltersPage {...props} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ResultPage {...props} />
        </Grid>
      </Grid>
      {props.showModalImportFile &&
        <ModalImportFile {...handleimportfile} />
      }
    </Box>
  );
}

export default ReportsPage;
