import * as React from 'react';
import sortBy from 'sort-by';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import Draggable from 'react-draggable';
import { Column } from '@material-table/core';
import { Table } from 'Shared/Components/Mui/Table/index';
import RegisterStructure from 'Shared/Constant/HomeTargetsAssociate/RecordStructure';
import { IHomeTargetsSupplier } from 'Module/HomeUser/Interface/HomeTargetsSupplier';
import { IFieldStructure } from 'Shared/Interfaces/ObjectSalesforce';
import { CircularProgress, Grid, Typography } from '@mui/material';
import { ISuppliersReportPage } from 'Module/SuppliersReport/Interface';
import { KEY_HOMETARGETSSUPPLIER_SELECTED } from 'Shared/Constant/Cache';

function PaperComponent(props: PaperProps) {
  const nodeRef = React.useRef(null);
  return (
    <Draggable
      nodeRef={nodeRef}
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper ref={nodeRef} {...props} />
    </Draggable>
  );
}

export default function SuppliersReportAssociateModalPage(props: ISuppliersReportPage) {
  const [open] = React.useState(true);

  const [columns, setColumns] = React.useState<Column<object>[]>([]);
  const toggleSetColumns = (values: Column<object>[]) => {
    setColumns(values);
  }

  const [data, setData] = React.useState<object[]>([]);
  const toggleSetData = (values: [IHomeTargetsSupplier?]) => {
    const _array: object[] = values.map(reg => reg || {});
    setData(_array);
  }

  const toggleSetItemSelected = (item: any) => {
    const _regAux: IHomeTargetsSupplier = item
    const _item: IHomeTargetsSupplier = {
      id: _regAux.id,
      codigo: _regAux.codigo,
      nome: _regAux.nome,
      documento: _regAux.documento,

      codigoFornec: _regAux.codigoFornec,
      documentFornec: _regAux.documentFornec,
      nomeFornec: _regAux.nomeFornec,

      valorBase: _regAux.valorBase,
      qtdeBase: _regAux.qtdeBase,

      metaMix: _regAux.metaMix,
      mix: _regAux.mix,
      percentMix: _regAux.percentMix,

      metaFaturamento: _regAux.metaFaturamento,
      faturamento: _regAux.faturamento,
      percentFaturmento: _regAux.percentFaturmento,

      criadoEm: _regAux.criadoEm,
      alteradoEm: _regAux.alteradoEm,
    };

    if (props.toggleSetTargetSupplierSelected) {
      props.toggleSetTargetSupplierSelected(_item);
    }
    if (props.toggleSetCache) {
      props.toggleSetCache(KEY_HOMETARGETSSUPPLIER_SELECTED, _item);
    }

    if (props.toggleShowModalAssociateProductDetail) {
      props.toggleShowModalAssociateProductDetail(true);
    }
  }

  const getFieldsStructure = React.useCallback((list: IFieldStructure[]) => {
    let _array: Column<object>[] = [];
    if (list && list.length > 0) {
      for (let regField of list.sort(sortBy('orderFormMain'))) {
        let _column: Column<object> = {}
        if (regField.viewFormMain) {
          switch (regField.type) {
            case 'checkbox':
              _column = {
                title: regField.label,
                field: regField.nameField,
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain,
                editComponent: (props) => {
                  return (
                    <input
                      type="checkbox"
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  );
                },
                render: (rowdata: any) => {
                  if (regField.nameField === 'active') {
                    return (<input type="checkbox" checked={rowdata.active} readOnly />)
                  }
                  else if (regField.nameField === 'isMeta') {
                    return (<input type="checkbox" checked={rowdata.isMeta} readOnly />)
                  }
                }
              }
              break;
            case 'currency':
              _column = {
                title: regField.label,
                field: regField.nameField,
                customSort: (a, b) => parseFloat(`a.${regField.nameField}`.replace(',', '.')) - parseFloat(`b.${regField.nameField}`.replace(',', '.')), // Ordena numericamente
                type: 'string', // numeric
                align: 'right',
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain
              }
              break;
            default:
              _column = {
                title: regField.label,
                field: regField.nameField,
                type: 'string',
                editable: regField.edit ? 'always' : 'never',
                hidden: regField.hiddenFormMain
              }
              break;
          }
          if (regField.defaultSort) {
            _column.defaultSort = regField.defaultSort === 'asc' ? 'asc' : 'desc';
          }
          if (regField.width) {
            _column.width = regField.width;
          }
          if (regField.cellStyle) {
            _column.cellStyle = regField.cellStyle;
          }


          switch (_column.field) {
            case 'valorBase':
              _column.customSort = (a: any, b: any) => parseFloat(a.valorBase.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.valorBase.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              // _column.render = (rowData: any) => parseFloat(rowData.valorBase.replace(/\./g, '').replace(/,/g, '.')).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL', currencyDisplay: 'narrowSymbol' }).replace('R$', ''); // Formata o número no formato pt-br sem moeda
              break;
            case 'qtdeBase':
              _column.customSort = (a: any, b: any) => parseFloat(a.qtdeBase.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.qtdeBase.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'metaMix':
              _column.customSort = (a: any, b: any) => parseFloat(a.metaMix.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.metaMix.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'mix':
              _column.customSort = (a: any, b: any) => parseFloat(a.mix.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.mix.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'percentMix':
              _column.customSort = (a: any, b: any) => parseFloat(a.percentMix.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.percentMix.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'metaFaturamento':
              _column.customSort = (a: any, b: any) => parseFloat(a.metaFaturamento.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.metaFaturamento.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'faturamento':
              _column.customSort = (a: any, b: any) => parseFloat(a.faturamento.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.faturamento.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'percentFaturmento':
              _column.customSort = (a: any, b: any) => parseFloat(a.percentFaturmento.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.percentFaturmento.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'caixas':
              _column.customSort = (a: any, b: any) => parseFloat(a.caixas.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.caixas.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'qtUnitCx':
              _column.customSort = (a: any, b: any) => parseFloat(a.qtUnitCx.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.qtUnitCx.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            case 'vlrUnitario':
              _column.customSort = (a: any, b: any) => parseFloat(a.vlrUnitario.replace(/\./g, '').replace(/,/g, '.')) - parseFloat(b.vlrUnitario.replace(/\./g, '').replace(/,/g, '.'));
              _column.type = 'numeric';
              break;
            default:
              break;
          }


          _array.push(_column)
        }
      }
    }
    toggleSetColumns(_array);

  }, [])

  const handleClose = () => {
    if (props.toggleShowModalAssociateDetail) {
      props.toggleShowModalAssociateDetail(false);
    }
  };

  React.useEffect(() => {
    getFieldsStructure(RegisterStructure);
    toggleSetData(props?.listModalTargetsAssociate || []);
  }, [props.listModalTargetsAssociate, props.dateUpdateInfo, getFieldsStructure])

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title"
      >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          <Grid container spacing={1}>
            <Grid item xs={6} sm={8} md={9} lg={10} xl={10}>
              <Typography component={'div'} align='left'>
                <span className='AssociatesReportModalTitle'>{props?.titleModalTargetsAssociate || 'Metas'}</span>
                {props.showLoadingUpdateTable && <span>&nbsp;&nbsp;<CircularProgress size={25} /></span>}
              </Typography>
            </Grid>
            <Grid item xs={6} sm={4} md={3} lg={2} xl={2}>
              <Typography component={'div'} align='right'>
                <Button
                  variant="contained"
                  color="success"
                  className="ButtonDanger"
                  type="button"
                  autoFocus
                  onClick={handleClose}
                >
                  <span>Fechar</span>
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'}>
            <Table dataParam={data}
              columns={columns}
              title='Metas associados'
              actionsColumnIndex={-1}
              href={'#'}
              toggleSelectItem={toggleSetItemSelected}
              toggleShowLoadingUpdateTable={props.toggleShowLoadingUpdateTable}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </div>
  );
}
